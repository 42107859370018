import React from 'react';

function Locksmith() {
  return (
  <>
    <h1>Locksmithing</h1>
    <p>
      In addition to bicycle repair works, we also provide key cutting services
      and other locksmith work. We are Master Locksmith Approved and do business
      with some of the leading names in the locksmith industry. We sell various
      levels of securities of bicycle locks, chains, padlocks, eurocylinders,
      rim cylinders, combination locks, and more. We also have a wide
      availability of automotive locksmith services such as key duplication, key
      programming, chip programming, OBD key programming.
      <ul>
        <li><b>Standard Keys</b> &mdash; Of course, we sell standard security
        padlocks, eurocylinders, keys, and some basic door hardware. We provide
        key cutting for blade keys, mortice keys, office furniture keys, and
        more.</li>

        <li><b>High Security Keys</b> &mdash; We have the ability to cut a wide
        range of the most common high security keys found in the U.K. and have
        a multitude of high security key blanks. We may even be able to cut your
        keys to code.</li>

        <li><b>Automotive Locksmiths</b> &mdash; We provide services for keys
        locked in your vehicle, key duplication, chip programming, fob
        programming, OBD key inclusion and exclusion, vehicle keys cut to code,
        key fob decoding, and whatever else you may require.</li>

        <li><b>Emergency Locksmithing</b> &mdash; There are not many people left
        who have not accidentally locked themselves out of their house at some
        point in time. Depending on availability, we can offer emergency
        locksmithing support at any time around the clock as long as our on-call
        locksmith is available for the job.</li>
      </ul>
    </p>
  </>
  );
}

export default Locksmith;