import React from 'react'

function ProfileCard(props) {
  let avatar = props.pic??'team_anon.jpg';
  return (<></>);
  return (
    <div className="profile--container">
      <img className="profile--photo" src={`/images/${avatar}`} alt=""/>
      <div className="profile--name">{props.name}</div>
      <div className="profile--role">{props.role}</div>
      <div className="profile--description">{props.children}</div>
    </div>
  );
}

export default ProfileCard;
