import React from 'react';

const day_text = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const day_today = new Date().getDay();
const SUN = 0;
const MON = 1;
const TUE = 2;
const WED = 3;
const THU = 4;
const FRI = 5;
const SAT = 6;

const openingtimes = [
  {day: MON, open: '09:00 AM', close: '04:30 PM'},
  {day: TUE, open: '09:00 AM', close: '06:30 PM'},
  {day: WED, open: '09:00 AM', close: '04:30 PM'},
  {day: THU, open: '09:00 AM', close: '04:30 PM'},
  {day: FRI, open: '09:00 AM', close: '04:30 PM'},
  {day: SAT, open: '09:00 AM', close: '04:30 PM'},
  {day: SUN, closed: true},
];



function InsertWorkDays() {
  let result = [];
  for(let i=0;i<openingtimes.length;i++) {
    let text_today = day_today === openingtimes[i].day?'ot--today':'';
    let text_day = day_text[openingtimes[i].day]??openingtimes[i].day;
    result.push(<div className={`ot--d ${text_today}`}>{text_day}</div>);
    if(!openingtimes[i].closed) {
      result.push(<div className={`ot--o ${text_today}`}>{openingtimes[i].open}</div>);
      result.push(<div className={`ot--c ${text_today}`}>{openingtimes[i].close}</div>);
    }
    else
      result.push(<div className={`ot--x ${text_today}`}>CLOSED</div>);
  }

  return result;
}

export default function OpeningTimetable() {
  return (
    <div className="ot--table">
      <div className="ot--o">Opens</div>
      <div className="ot--c">Closes</div>
      <InsertWorkDays/>
    </div>
  );
}
