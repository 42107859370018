import React from 'react';

function History() {
  return (
  <>
    <h1>Business History</h1>
    <p>
      This information is accurate to the best of our knowledge as of March 2022.
    </p>
    <p>
      Starting back in the early 1930s, Lee High Road saw the creation of a
      row of buildings that were to be called Market Terrace. This was to
      become a range of numbers, 270&ndash;292 Lee High Road. The intention
      was to provide the local community with more shops. Currently and for
      the forseeable future, Pedal It occupies 290 Lee High Road. According to
      a local historian, potentially one of the first businesses to occupy 290
      Lee High Road may have been a greengrocer called Charles Hayden, or at
      least was present there in 1951. A few years later, a young and keen
      bicycle enthusiast and mechanic by the name Ernie H. Young had moved
      into 290 Lee High Road after expanding from his already existing
      business established in 1941 located nearby at 248 Lee High Road. In the
      1960s, Ernie expanded his shop at 290 Lee High Road into next door at
      288 which was formally a ladies hairdressers since the 1930s when Market
      Terrace was established. The name "Market Terrace" still proudly exists
      above shops 280&ndash;282 Lee High Road.
    </p>

    <p>
      The business was passed onto his son who later sold it in the 1990s to
      Bob Donnington who had worked for another well-known local cycling name,
      Holdsworth. The Young's name lived on until the early 2000s when it was
      sold and renamed <b>The Bike Shop</b> (observed in June and July 2008)
      and later titled <b>www.bikeshoplewisham.co.uk</b> (observed in February
      2009 to May 2012). The business was sold again in 2014 to Markus
      Blanshard who renamed the shop to <b>Pedal It</b> who tried to bring
      back to life what Young's Cycles once stood for and building a more
      loyal, passionate, and involved community through helping local schools,
      younger generations, and the unemployed by offering work experience
      programs and other training.
    </p>
    <p>
      Between May 2016 and May 2017, the shop owners decided to let go of 288
      Lee High Road and became a single unit shop again where 288 became a
      local printers called Prints Palace and a year later became Elite Hair
      Lounge that still remains. Markus and colleague Jay opened up the first
      Pedal It website to sell various bikes and bike accessories. A lot of
      online presence related to Pedal It began with Markus and Jay.
    </p>
    <p>
      In 2016, the shop was sold once again to bicycle enthusiast Dan
      Davidson who is the current owner. Dan had very little to get the
      business going again but through sheer perseverence brought it back to
      life, buying, selling, and reparing bicycles and accessories. The
      current team of workers have a wide variety of backgrounds, experiences,
      ages, abilities, and characteristics; all successfully brought together
      by Dan who has proven successful with plans to potentially expand the
      shop once again back into 288 Lee High Road.
    </p>
    <p>
      Since 2022, Dan has also set aside some space in the workshop to begin
      growing his very successful business pleasure of locksmithing. Providing
      services such as key duplication, selling locks, chains, padlocks and
      various accessories. This has grown into the automotive industry,
      enabling customers to have new vehicle keys, reprogrammed chips, fobs,
      or maybe they've simply locked their keys inside their car.
    </p>
    <p>
      On March 25th, 2022, the Pedal It website was taken over and rebuilt in
      aid to bring the shop back to life online and provide visitors with
      information and news related to the business.
    </p>
    <p>
      A lot of the history was gathered together using various sources with
      special thanks to local historian Paul B., former owner Markus B., and
      Google Street View.
    </p>
  </>);
}

export default History;