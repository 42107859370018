import React from 'react';
import OpeningTimetable from '../components/OpeningTimetable';

function launchPhone() {
  window.location = 'tel:+442088526680';
}

function Contact() {
  return (
  <>
    <div className="contact--phone" onClick={()=>launchPhone()}>
      <img src="/images/phone.svg" alt=""/>
      020 8852 6680
    </div>
    <hr className="hr--short"/>
    <p>
      Some times may vary and we aim to announce any changes here. If you have
      any doubts about times, please feel free to call ahead of time.
    </p>
    <OpeningTimetable/>

  </>
  );
}
/*    <p>
      If the shop is closed and you need to pump up your tyres, there is a
      manual tyre pump free for everyone to use just outside the premises.
    </p> */

export default Contact;