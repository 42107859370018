import React from 'react';
import ProfileCard from '../components/ProfileCard'

function Team() {
  return (
    <>
    <h1>Meet The Team</h1>
    <p>
      Our team is made up of a select few dedicated specialists who have been in
      the industry for a very long time and we are thankful that they provide their
      expertise, knowledge, and skills to bring Lewisham's longest running and well-known
      bicycle shop to the highest standard. The shop also has younger members in
      the team who are keen and enthusiastic to learn and gain experience with
      bicycle mechanics and have proven successful time and time again. Here you
      can become familiar with most of them.
    </p>
    <div className="wrapper wrapper--center profile--section">
      <ProfileCard name="Dan Davidson" role="Business Owner &amp; Mechanic" pic="team_dan.jpg">
        Since taking over the shop in 2016, Dan has been the go-to person any
        time something is required to get the job done. Incredibly dedicated
        and doesn't back down until the task is done. He's never afraid to
        get his hands dirty, you'll always see him taking care of business.
      </ProfileCard>

      <ProfileCard name="Jamie Mckechnie" role="Senior Mechanic" pic="team_jamie.jpg"/>
      <ProfileCard name="Vince" role="Senior Mechanic"/>
      <ProfileCard name="Harvey Topsom" role="Senior Mechanic" pic="team_guy2.jpg"/>

      <ProfileCard name="Josh Wilson" role="Web Developer" pic="team_josh.jpg">
        Upon realising the shop had always lacked any real website, as a good
        friend of Dan's, Josh saw it as his responsibility to create one to
        help promote the business and provide accurate and up-to-date
        information about the business.
      </ProfileCard>

      <ProfileCard name="Anonymous" role="">
        While we would love to introduce each and every one of our hard
        workers of the team, we must also respect that some of them wish to
        keep their online presence minimal and have chosen to opt out of being
        listed.
      </ProfileCard>
    </div>
    </>
  );
}

export default Team;