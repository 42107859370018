import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Home from './pages/Home';
import History from './pages/History';
import Team from './pages/Team';
import Locksmith from './pages/Locksmith';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

  return (
    <Router>
      <Navbar/>
      <main>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/team" element={<Team/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/history" element={<History/>} />
        <Route path="/locksmith" element={<Locksmith/>} />
        <Route path="*" element={<Home/>} />
      </Routes>
      </main>
      <Footer/>
    </Router>
  );
}

export default App;
