import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
  <>
    <p>
      Welcome to Pedal It, Lewisham's longest running bicycle repair shop. This
      website is still under construction.
    </p>
    <div className="home--links">
      <a href="https://www.google.com/maps/place/290+Lee+High+Road,+Lewisham,+London+SE13+5PJ,+UK/@51.4584924,0.004101">Find us on Google Maps</a>
      or
      <a href="tel:+442088526680">Call us on 020 8852 6680</a>
    </div>
    <p>
      We are located at 290 Lee High Road, Lewisham, SE13 5PJ. For opening
      times, please go to <Link to="/contact">Contact Us</Link>.
    </p>
  </>
  );
}

export default Home;