import React from 'react';

function Footer() {
  return (<></>);
  return (
    <footer>
      <p>
        &copy; 2022 Pedal It; all rights reserved.
      </p>
    </footer>
  );
}

export default Footer;